import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"

const Hero = () => {
  return (
    <>
      <div className="relative h-screen max-w-[1440px] mx-auto max-h-[1080px] bg-neutral-900">
        <StaticImage
          src="../../images/bg-hero.png"
          alt=""
          className="!absolute inset-0 z-10"
          objectFit="cover"
        />

        <Section className="relative z-20 h-full tablet:grid place-content-center">
          <Container>
            <div className="mt-32 tablet:w-1/2 tablet:mt-0">
              <SectionTitle isHero className="mb-4">
                Redesign to Gatsby. Simple pricing.
              </SectionTitle>
              <p className="mb-8 text-white text-body-lg">
                We serve globally to modernise static sites to take advantage of
                the blazing speeds of Gatsby while eliminating WordPress monthly
                maintenance and even probably, server costs.
              </p>
              <Link
                to="#pricing"
                className="inline-block p-3 font-bold text-white uppercase text-body-sm"
              >
                <div className="flex items-center">
                  <div className="h-[2px] bg-white w-20 mr-2"></div>
                  <div className="font-bold text-white uppercase text-body-sm">
                    Pricing
                  </div>
                </div>
              </Link>
            </div>
          </Container>
        </Section>
      </div>
    </>
  )
}

export default Hero
