import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"
import SectionText from "../../components/section-text"

const Approach = () => {
  return (
    <>
      <Section className=" bg-primary-100 !py-0">
        <div className="grid tablet:grid-cols-2">
          <div className="laptop:px-[9.5rem] px-5 tablet:px-10 tablet:py-64 py-12 bg-primary-500">
            <SectionTitle className="mb-2 !text-white">
              Exceptional <br /> craftsmanship
            </SectionTitle>
            <SectionText className="text-white">
              Our clients enjoy a well-engineered redesigned website, residing
              on lightning-fast servers. Our price-performance curve is
              unmatched, delivering to maximise the design value of production
              per dollar.
            </SectionText>
          </div>

          <div className="flex items-center laptop:py-24 laptop:pr-[9.5rem] tablet:py-10 tablet:pr-10 px-5 tablet:pl-0">
            <div className="flex items-center justify-center w-full p-20 bg-white laptop:p-28 tablet:h-full">
              <StaticImage alt="" src="../../images/logo-circled.png" />
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default Approach
