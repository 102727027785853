import React from "react"

const SectionTitle = ({ isHero = false, children, className = "" }) => {
  return (
    <>
      {isHero ? (
        <h1
          className={`font-bold text-white text-display-md tablet:text-display-lg laptop:text-display-xl ${className}`}
        >
          {children}
        </h1>
      ) : (
        <h2
          className={`font-bold text-neutral-900 text-display-sm tablet:text-display-md laptop:text-display-lg ${className}`}
        >
          {children}
        </h2>
      )}
    </>
  )
}

export default SectionTitle
