import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"
import SectionText from "../../components/section-text"

const Portfolio = () => {
  return (
    <>
      <div id="portfolio">
        <Section className="bg-white">
          <Container>
            <div className="mb-12 text-center tablet:mb-20 laptop:mb-24">
              <SectionTitle>Featured work</SectionTitle>
            </div>

            <div className="grid gap-20 laptop:gap-24">
              <div className="bg-[#D9B456] pt-16 px-5 text-center overflow-hidden relative">
                <a
                  href="https://ihsanlearningcentre.com/"
                  className="!absolute top-0 block px-5 py-3 font-semibold text-white right-4 tablet:right-7 bg-primary-500"
                  target="_blank"
                >
                  VISIT SITE
                </a>
                <div className="max-w-[544px] mx-auto">
                  <h3 className="mb-2 font-bold text-white text-display-xs tablet:text-display-sm laptop:text-display-md tablet:mb-4">
                    Ihsan Learning Centre
                  </h3>
                  <SectionText className="mb-12 text-white">
                    Your one-stop Arabic learning centre, from kids to working
                    adults.
                  </SectionText>
                  <StaticImage
                    src="../../images/work-1.png"
                    alt=""
                    className="mb-0"
                  />
                </div>
              </div>
              <div className="bg-[#38619E] pt-16 px-5 text-center overflow-hidden relative">
                <a
                  href="https://www.encreva.com/"
                  className="!absolute top-0 block px-5 py-3 font-semibold text-white right-4 tablet:right-7 bg-primary-500"
                  target="_blank"
                >
                  VISIT SITE
                </a>
                <div className="max-w-[544px] mx-auto">
                  <h3 className="mb-2 font-bold text-white text-display-xs tablet:text-display-sm laptop:text-display-md tablet:mb-4">
                    Encreva
                  </h3>
                  <SectionText className="mb-12 text-white">
                    From security to optimisations, Encreva is the one-stop
                    WordPress care centre your business can count on.
                  </SectionText>
                  <StaticImage
                    src="../../images/work-2.png"
                    alt=""
                    className="mb-0"
                  />
                </div>
              </div>
            </div>
          </Container>
        </Section>
      </div>
    </>
  )
}

export default Portfolio
