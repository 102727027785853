import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"
import SectionText from "../../components/section-text"

const Approach = () => {
  return (
    <>
      <Section className="overflow-hidden bg-primary-100">
        <Container>
          <div className="mb-12 text-center">
            <SectionTitle>Supercharge your brand</SectionTitle>
          </div>
          <div className="grid laptop:grid-cols-2">
            <div className="pt-32 mb-16 laptop:pr-20 tablet:pt-60 laptop:pt-40">
              <div className="relative flex items-end justify-start laptop:justify-end">
                <StaticImage
                  alt=""
                  src="../../images/content-2.png"
                  width={308}
                  className="w-[174px] tablet:w-auto"
                />
                <StaticImage
                  alt=""
                  src="../../images/content-1.png"
                  width={300}
                  className="!absolute top-0 left-0 translate-x-2/3 -translate-y-2/3 laptop:left-0 laptop:translate-x-0 laptop:-translate-y-1/3 !w-[174px] tablet:!w-auto"
                />
              </div>
            </div>
            <div className="grid gap-8 tablet:gap-12 laptop:pt-20">
              <div>
                <h3 className="flex items-center mb-2 font-bold text-display-xs tablet:text-display-sm text-neutral-900">
                  <span className="w-[6px] h-[6px] bg-primary-500 rounded-full inline-block flex-shrink-0 mr-2"></span>
                  Convey your authority
                </h3>
                <SectionText>
                  Having a well designed website establishes authority as it
                  projects trustworthiness. Professional appearance feels solid
                  and clarity in navigating through your site conveys respects
                  to customers.
                </SectionText>
              </div>
              <div>
                <h3 className="flex items-center mb-2 font-bold text-display-xs tablet:text-display-sm text-neutral-900">
                  <span className="w-[6px] h-[6px] bg-primary-500 rounded-full inline-block flex-shrink-0 mr-2"></span>
                  Expand mind share
                </h3>
                <SectionText>
                  A well-designed website develops consumer awareness or
                  popularity, hence should be part of your marketing strategy to
                  enhance brand experience.
                </SectionText>
              </div>
              <div>
                <h3 className="flex items-center mb-2 font-bold text-display-xs tablet:text-display-sm text-neutral-900">
                  <span className="w-[6px] h-[6px] bg-primary-500 rounded-full inline-block flex-shrink-0 mr-2"></span>
                  Market differentiation
                </h3>
                <SectionText>
                  An outdated website feels old and clunky and will likely cause
                  high bounce rates. People are visual first and to capture
                  their attention, designs must be interesting to look at,
                  standing out from competition.
                </SectionText>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Approach
