import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Hero from "../sections/home/Hero"
import Approach from "../sections/home/Approach"
import Mockup from "../sections/home/Mockup"
import Content from "../sections/home/Content"
import Branding from "../sections/home/Branding"
import ApproachCards from "../sections/home/ApproachCards"
import Portfolio from "../sections/home/Portfolio"
import Techs from "../sections/home/Techs"
import Pricing from "../sections/home/Pricing"
import Gallery from "../sections/home/Gallery"
import Contact from "../sections/home/Contact"

const IndexPage = ({ data }) => {
  return (
    <>
      <GatsbySeo
        title={`Elegares`}
        description={`We serve globally to modernise static sites to take advantage of the blazing speeds of Gatsby while eliminating WordPress monthly maintenance and even probably, server costs.`}
      />
      <Hero />
      <Approach />
      <Mockup />
      <Content />
      <Branding />
      <ApproachCards />
      <Portfolio />
      <Techs />
      <Pricing />
      <Gallery />
      <Contact />
    </>
  )
}

export default IndexPage
