import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"

const Pricing = () => {
  return (
    <>
      <div>
        <Section className="">
          <Container className="tablet:pt-14">
            <div className="grid gap-5 tablet:grid-cols-12 tablet:gap-7">
              <div className="laptop:col-span-3 tablet:col-span-5 tablet:w-[150%] relative z-10">
                <StaticImage
                  className="mb-10 w-[80%]"
                  src="../../images/logo.svg"
                  alt=""
                />
                <SectionTitle className="[text-shadow:1px_1px_0px_rgba(250,246,242,1),-1px_1px_0px_rgba(250,246,242,1),1px_-1px_0px_rgba(250,246,242,1),-1px_-1px_0px_rgba(250,246,242,1)]">
                  Make us your partner agency for your redesigning needs.
                </SectionTitle>
              </div>
              <div className="overflow-hidden laptop:col-span-4 tablet:col-span-7 tablet:-mt-14">
                <StaticImage
                  src="../../images/gallery-image-1.png"
                  alt=""
                  className="w-full "
                />
              </div>
              <div className="flex flex-col gap-5 tablet:flex-row laptop:flex-col laptop:col-span-5 tablet:col-span-12 tablet:gap-7">
                <StaticImage
                  src="../../images/gallery-image-2.png"
                  alt=""
                  className="w-full tablet:w-1/2 laptop:w-full"
                />
                <div className="w-full tablet:w-1/2 laptop:w-auto">
                  <StaticImage
                    src="../../images/gallery-image-3.png"
                    alt=""
                    className="w-full laptop:w-auto"
                  />
                </div>
              </div>
            </div>
          </Container>
        </Section>
      </div>
    </>
  )
}

export default Pricing
