import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import Recaptcha from "react-google-recaptcha"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"
import SectionText from "../../components/section-text"

const RECAPTCHA_KEY = "6LcbyAYfAAAAANg9CQNs4_bsc8aqgRTj2huqkgU6"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Contact = () => {
  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()
  const [buttonDisabled, setButtonDisabled] = React.useState(true)
  const [buttonLabel, setButtonLabel] = React.useState("Submit")

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setButtonLabel("Submitting...")
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...state,
      }),
    })
      .then(() => {
        setButtonLabel("Submit")
        setTimeout(() => {
          navigate("/success")
        }, 0)
      })
      .catch(error => {
        setButtonLabel("Submit")
        alert(error)
      })
  }

  return (
    <>
      <div id="contact">
        <Section className="bg-white">
          <Container className="grid gap-12 laptop:grid-cols-12">
            <div className="flex flex-col laptop:col-span-7">
              <SectionTitle className="mb-2">Let’s work together</SectionTitle>
              <SectionText className="mb-8 tablet:mb-12 laptop:mb-24">
                Have an enquiry? Fill out the form to contact our team
              </SectionText>
              <div className="mb-8 tablet:mb-12">
                <StaticImage
                  src="../../images/signeture.svg"
                  alt=""
                  className="w-[250px] tablet:w-auto"
                />
              </div>
              <div className="flex items-start laptop:mt-auto">
                <StaticImage
                  src="../../images/svg/location.svg"
                  alt=""
                  className="flex-shrink-0 mr-4"
                />
                <SectionText className="text-neutral-900">
                  2 Venture Drive #14-02 Vision Exchange Singapore 608526
                </SectionText>
              </div>
            </div>
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-recaptcha="true"
              className="grid gap-6 p-6 laptop:col-span-5 tablet:p-10 bg-primary-50"
              onSubmit={handleSubmit}
            >
              {/* You still need to add the hidden input with the form name to your JSX form */}
              <input type="hidden" name="form-name" value="contact" />
              <noscript>
                <p>This form won’t work with Javascript disabled</p>
              </noscript>
              <label className="block gap-6">
                <span className="text-gray-700">Your name</span>
                <input
                  type="text"
                  className="block w-full h-12 mt-2 border-0 focus:ring-0 focus:bg-neutral-50"
                  placeholder="John Doe"
                  required
                  name="Name"
                  onChange={handleChange}
                />
              </label>
              <label className="block gap-6">
                <span className="text-gray-700">Email address </span>
                <input
                  type="email"
                  className="block w-full h-12 mt-2 border-0 focus:ring-0 focus:bg-neutral-50"
                  placeholder="john@example.com"
                  required
                  name="Email"
                  onChange={handleChange}
                />
              </label>

              <label className="block gap-6">
                <span className="text-gray-700">Message</span>
                <textarea
                  className="block w-full mt-2 border-0 focus:ring-0 focus:bg-neutral-50"
                  rows={5}
                  spellCheck="false"
                  defaultValue={""}
                  required
                  name="Message"
                  onChange={handleChange}
                />
              </label>
              {/* Recaptcha */}
              <Recaptcha
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="normal"
                id="recaptcha-google"
                onChange={() => setButtonDisabled(false)}
              />
              <div>
                <button
                  type="submit"
                  disabled={buttonDisabled}
                  className="inline-block px-5 py-3 font-semibold text-white bg-primary-500 disabled:bg-neutral-500/60 disabled:cursor-not-allowed"
                >
                  {buttonLabel}
                </button>
              </div>
            </form>
          </Container>
        </Section>
      </div>
    </>
  )
}

export default Contact
