import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"
import SectionText from "../../components/section-text"
import CardText from "../../components/card-text"

const ApproachCards = () => {
  return (
    <>
      <Section className=" bg-primary-100">
        <Container>
          <SectionTitle className="mb-2">Approach</SectionTitle>
          <SectionText className="tablet:hidden">
            We pursue cost cutting through efficiency and this cost saving is
            passed down to clients. A large part of this reduction comes from
            the fact that all creative assets to be used comes from your
            existing website and additional content is presented during
            purchase. This streamlines the pre-production planning phase. Our
            work starts with this phase and tracks with you through launch and
            beyond. The timeline below provides a step-by-step view of how our
            services intersect with your project’s journey.
          </SectionText>

          <div className="grid gap-16 pt-8 tablet:gap-20 laptop:grid-cols-4 laptop:gap-8">
            <div className="relative flex laptop:block">
              <StaticImage
                alt=""
                src="../../images/line-dotted-v-1.png"
                className="!absolute top-20 left-7 laptop:!hidden"
              />
              <StaticImage
                alt=""
                src="../../images/line-dotted-h-1.png"
                className="!absolute !hidden top-7 left-24 laptop:!block"
              />
              <div className="mb-4 mr-4 laptop:mr-0 flex-shrink-0 bg-white rounded-full w-[72px] h-[72px] flex items-center justify-center">
                <StaticImage src="../../images/svg/feature-1.svg" alt="" />
              </div>
              <div className="pt-2 laptop:pt-0">
                <h4 className="font-bold text-body-lg text-neutral-900">
                  Evaluation
                </h4>
                <CardText className="mt-1">
                  Ensure that your site is static to ensure transition to Gatsby
                  is possible.
                </CardText>
              </div>
            </div>
            <div className="relative flex laptop:block">
              <StaticImage
                alt=""
                src="../../images/line-dotted-v-2.png"
                className="!absolute top-20 left-7 laptop:!hidden"
              />
              <StaticImage
                alt=""
                src="../../images/line-dotted-h-2.png"
                className="!absolute !hidden top-7 left-24 laptop:!block"
              />
              <div className="mb-4 mr-4 laptop:mr-0 flex-shrink-0 bg-white rounded-full w-[72px] h-[72px] flex items-center justify-center">
                <StaticImage src="../../images/svg/feature-2.svg" alt="" />
              </div>
              <div className="pt-2 laptop:pt-0">
                <h4 className="font-bold text-body-lg text-neutral-900">
                  Design
                </h4>
                <CardText className="mt-1">
                  Analyse the existing user interface and content, and sketch
                  out the product to align the user needs.
                </CardText>
              </div>
            </div>
            <div className="relative flex laptop:block">
              <StaticImage
                alt=""
                src="../../images/line-dotted-v-1.png"
                className="!absolute top-20 left-7 laptop:!hidden"
              />
              <StaticImage
                alt=""
                src="../../images/line-dotted-h-1.png"
                className="!absolute !hidden top-7 left-24 laptop:!block"
              />
              <div className="mb-4 mr-4 laptop:mr-0 flex-shrink-0 bg-white rounded-full w-[72px] h-[72px] flex items-center justify-center">
                <StaticImage src="../../images/svg/feature-3.svg" alt="" />
              </div>
              <div className="pt-2 laptop:pt-0">
                <h4 className="font-bold text-body-lg text-neutral-900">
                  Develop
                </h4>
                <CardText className="mt-1">
                  Convert the designs into a live site.
                </CardText>
              </div>
            </div>
            <div className="relative flex laptop:block">
              <div className="mb-4 mr-4 laptop:mr-0 flex-shrink-0 bg-white rounded-full w-[72px] h-[72px] flex items-center justify-center">
                <StaticImage src="../../images/svg/feature-4.svg" alt="" />
              </div>
              <div className="pt-2 laptop:pt-0">
                <h4 className="font-bold text-body-lg text-neutral-900">
                  Deploy
                </h4>
                <CardText className="mt-1">
                  Final checks and launch. Enjoy maintenance-free and serverless
                  experience.
                </CardText>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default ApproachCards
