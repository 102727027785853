import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"
import SectionText from "../../components/section-text"

const Mockup = () => {
  return (
    <>
      <div className="pb-[460px] laptop:pb-0">
        <Section className="">
          <Container>
            <div className="grid p-12 bg-primary-500 laptop:grid-cols-2">
              <div className="pb-16 laptop:pb-0">
                <SectionTitle className="!text-white mb-4">
                  Responsive design{" "}
                </SectionTitle>
                <SectionText className="mb-10 text-white">
                  According to Ironpaper, 80% of web visitors will stop engaging
                  with a website that does not display well on their device. At
                  this point, it’s natural to hit the back button and go for a
                  competitor. By including mobile-optimised version of your
                  redesign, we can help prevent that from happening.
                </SectionText>
                <Link
                  to="#pricing"
                  className="inline-block p-3 font-bold text-white uppercase text-body-sm"
                >
                  <div className="flex items-center">
                    <div className="h-[2px] bg-white w-20 mr-2"></div>
                    <div className="font-bold text-white uppercase text-body-sm">
                      Pricing
                    </div>
                  </div>
                </Link>
              </div>
              <div className="relative flex justify-center -mx-5 tablet:mx-0 laptop:items-center">
                <StaticImage
                  className="!absolute -translate-y-10 laptop:translate-y-4"
                  src="../../images/mockup-phone.png"
                  alt=""
                  width={320}
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>
    </>
  )
}

export default Mockup
