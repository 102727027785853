import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"

const Techs = () => {
  return (
    <>
      <Section className=" bg-primary-500">
        <Container>
          <div className="grid gap-12 text-center tablet:gap-0 tablet:grid-cols-12 tablet:text-left">
            <div className="grid tablet:col-span-7 place-items-center">
              <SectionTitle className="mb-2 !text-white">
                Figma. Gatsby. Netlify. Amazing design value at affordable
                pricing.
              </SectionTitle>
            </div>

            <div className="flex tablet:col-span-5 tablet:pl-8 ">
              <StaticImage
                alt=""
                src="../../images/brand-logos.png"
                className="block mx-auto w-[275px] tablet:w-auto"
              />
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Techs
