import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"

const Pricing = () => {
  return (
    <>
      <div>
        <Section className="relative overflow-hidden bg-neutral-900">
          <StaticImage
            className="!absolute inset-0"
            src="../../images/bg-pricing.png"
            alt=""
          />
          <Container className="relative pt-[58rem] tablet:pt-[78rem] laptop:pt-[68rem]">
            <div id="pricing">
              <SectionTitle className="!text-white mb-12">
                Jawdroppingly low prices
              </SectionTitle>
              <div className="flex flex-col gap-8 p-6 laptop:flex-row tablet:gap-16 tablet:p-12 bg-primary-500">
                <div className="p-8 !pb-6 text-center text-white bg-primary-600 tablet:p-12 whitespace-nowrap">
                  <h5 className="mb-8 font-medium text-body-lg">
                    FLAT PRICING
                  </h5>
                  <SectionTitle className="!text-white mb-4">
                    $400{" "}
                    <small className="font-medium text-body-sm">per page</small>
                  </SectionTitle>
                  <p className="mb-10 text-white text-body-sm">
                    Irregardless of page length
                  </p>
                  <Link
                    to="#contact"
                    className="block p-3 mx-auto font-bold text-white text-body-sm"
                  >
                    <div className="flex items-center justify-center">
                      <div className="mr-3 font-semibold text-white text-body-md">
                        Get Started
                      </div>
                      <StaticImage
                        src="../../images/svg/arrow-right.svg"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col justify-center text-white text-body-sm tablet:text-display-xs">
                  <p className="mb-5 tablet:mb-6">
                    We don’t play games when it comes to your wallet. At
                    Elegares, what you pay at sign-up is never followed by,
                    “Surprise, there’s more to pay!”
                  </p>
                  <p>
                    Like with everything else, we believe in keeping it
                    straightforward, simple, and honest. If you think we are
                    doing that, call us out, respectfully of course. After all,
                    we’re humans, we’re bound to make mistakes.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </Section>
      </div>
    </>
  )
}

export default Pricing
