import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Section from "../../components/section"
import Container from "../../components/container"
import SectionTitle from "../../components/section-title"
import SectionText from "../../components/section-text"

const Approach = () => {
  return (
    <>
      <div id="about">
        <Section className="">
          <Container>
            <div className="grid gap-4 tablet:gap-6 laptop:grid-cols-2 laptop:gap-8">
              <SectionTitle>
                We are fascinated with beauty & design.
              </SectionTitle>
              <SectionText>
                Elegares was founded with a desire for the masses to have access
                to quality web redesigning services. We don’t settle and are
                here to stay.
              </SectionText>
            </div>
          </Container>
        </Section>
      </div>
      <Section className="!pt-0 !pb-28 tablet:!pb-[8.5rem] laptop:!pb-32">
        <div className="overflow-hidden">
          <StaticImage
            className="laptop:max-w-full -mx-44 laptop:mx-auto"
            src="../../images/image-group.png"
            alt=""
          />
        </div>
        <Container className="relative">
          <div className="!absolute right-0 w-full translate-y-1/4 -bottom-14 tablet:translate-y-1/4 laptop:translate-y-1/4">
            <div className="grid place-content-center laptop:grid-cols-2 tablet:grid-cols-3 ">
              <div></div>
              <div className="col-start-2 p-6 tablet:p-16 tablet:col-span-2 laptop:col-auto bg-primary-500">
                <SectionTitle className="!text-white mb-4">
                  Built from love
                </SectionTitle>
                <SectionText className="mb-10 text-white">
                  With purpose & passion.
                </SectionText>
                <Link
                  to="#approach"
                  className="inline-block p-3 font-bold text-white uppercase text-body-sm"
                >
                  <div className="flex items-center">
                    <div className="h-[2px] bg-white w-20 mr-2"></div>
                    <div className="font-bold text-white uppercase text-body-sm">
                      Our Approach
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <div id="approach">
        <Section className="relative">
          <StaticImage
            src="../../images/bg-pattern.png"
            alt=""
            className="!absolute inset-0"
          />
          <Container className="relative">
            <div className="text-center laptop:max-w-[992px] tablet:max-w-[464px] max-w-[300px] mx-auto">
              <h3 className="text-body-xl tablet:text-display-sm laptop:text-display-lg">
                We handle the redesign matters, including responsive design and
                migration so our clients are free to focus on what matters, all
                at fully transparent prices. We want you to succeed on the
                digital arena, because when you do, we do too.
              </h3>
            </div>
          </Container>
        </Section>
      </div>
    </>
  )
}

export default Approach
